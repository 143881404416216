import React from "react";
import "../css/report.scss";
import "../css/them.scss";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import {
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendTheme,
} from "@mui/material/styles";

import log from "loglevel";

function Report() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const theme = extendTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: () => ({}),
        },
      },
    },
  });

  const [open, setOpen] = React.useState(false);
  const openReportModal = () => setOpen(true);
  const closeReportModal = () => setOpen(false);

  const [selectedField, setSelectedField] = React.useState("");

  const handleError_FieldChange = (event) => {
    log.debug(`Selected field: ${selectedField}`);
    setSelectedField(event.target.value);
  };

  return (
    <CssVarsProvider theme={theme}>
      <div id="reportmodal">
        <Button onClick={openReportModal}>Report info</Button>
        <Modal
          id="modal"
          open={open}
          onClose={closeReportModal}
          container={() => document.getElementById("person-details")}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box id="box" sx={style}>
            <h2 className="modalTitle">Report/Suggests info change</h2>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                What is Wrong?
              </InputLabel>
              <Select
                id="Error_Field"
                label="Error Field"
                onChange={handleError_FieldChange}
              >
                <MenuItem>Emails</MenuItem>
                <MenuItem>Phones</MenuItem>
                <MenuItem>Telegram</MenuItem>
                <MenuItem>Address</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <TextField id="Suggests" label="Suggests" variant="outlined" />
            </FormControl>
            <FormControl fullWidth>
              <Button variant="outlined" color="primary">
                Submit
              </Button>
            </FormControl>
          </Box>
        </Modal>
      </div>
    </CssVarsProvider>
  );
}

export default Report;
