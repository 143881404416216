import AirTimeTab from "./airTimeTab";
import { useTranslation } from "react-i18next";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { Button } from "@mui/material";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

function TabsComponent({ data }) {
  const t = useTranslation().t;
  const lang = localStorage.getItem("lang") || "en";
  const orgs = data.orgs || [];
  const org_form = data.org_form || [];
  const orgStuts = (e) => {
    if (e === true) {
      return "active";
    } else if (e === false) {
      return "inactive";
    } else {
      return "- - -";
    }
  };

  return (
    <Tabs>
      <TabList>
        <Tab>{t("ORGANIZATIONS")}</Tab>
        <Tab>{t("AIR TIME")}</Tab>
        {data?.telegram_channels !== null && <Tab>TELEGRAM</Tab>}
      </TabList>
      <TabPanel>
        <div className="orgs">
          {(orgs.length > 0 &&
            orgs.map((data, index) => (
              <div key={index}>
                <div className="org">
                  <div className="box">
                    <h4>name:</h4>
                    <p>
                      {lang === "en"
                        ? data.name.en || "- - -"
                        : lang === "uk"
                        ? data.name.uk || "- - - (Ukrainian)"
                        : lang === "ru"
                        ? data.name.ru || "- - - (Russian)"
                        : "- - -"}
                    </p>
                  </div>
                  <div className="box">
                    <h4>stuts</h4>
                    <p>{orgStuts(data.active) || "- - -"}</p>
                  </div>
                  <div className="box">
                    <h4>org type:</h4>
                    <p>{data.org_type.en || "- - -"}</p>
                  </div>
                  <div className="box">
                    <h4>org form:</h4>
                    <p>{org_form || "- - -"}</p>
                  </div>
                  <div className="box">
                    <h4>parent org:</h4>
                    <p>{data.parent_org_name.en || "- - -"}</p>
                  </div>
                  <div className="box">
                    <h4>role_in_org:</h4>
                    <p>{data.role_in_org.en || "- - -"}</p>
                  </div>
                  <div className="box">
                    <h4>role_category:</h4>
                    <p>{data.role_category.en || "- - -"}</p>
                  </div>
                  <div className="box">
                    <h4>state_affiliated:</h4>
                    <p>{data.state_affiliated || "- - -"}</p>
                  </div>
                </div>
                <hr className="one" />
              </div>
            ))) || (
            <div className="submitData">
              <p>{t("No available info yet")}</p>
              <Button>{t("Submit data")}</Button>
            </div>
          )}
        </div>
      </TabPanel>
      <TabPanel>
        <AirTimeTab data={data} />
      </TabPanel>

      {data?.telegram_channels !== null && (
        <TabPanel>
          {data?.telegram_channels?.map((data, index) => (
            <table key={index}>
              <tbody>
                <tr>
                  <td>
                    <h4>Channel name:</h4>
                  </td>
                  <td>
                    <p>{data.title}</p>
                  </td>
                </tr>
                <tr id="address">
                  <td>
                    <h4>Date started:</h4>
                  </td>
                  <td>
                    <p>{data.date_created}</p>
                  </td>
                </tr>
                <tr id="address">
                  <td>
                    <h4>Current population:</h4>
                  </td>
                  <td>
                    <p>{data.population}</p>
                  </td>
                </tr>
              </tbody>
            </table>
          ))}
        </TabPanel>
      )}
    </Tabs>
  );
}

export default TabsComponent;
