import { useState, useEffect } from "react";
import "../css/theoryArticle.scss";
import "../css/loader.scss";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ReactMarkdown from "react-markdown";
import { theoryUrl, requestArticle } from "../hooks/RestClient.jsx";
import { useTranslation } from "react-i18next";

const TheoryArticle = () => {
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState([]);
  const t = useTranslation().t;
  const lang = t("lang");

  // get artical id
  let param = useParams();
  let pramsId = Number(param.id);
  console.log(content);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await requestArticle(theoryUrl(), pramsId);

        if (res.error) {
          console.log(res.error);
          return;
        }
        console.log("res", res);
        setContent(res);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [pramsId]);

  const defaultImage =
    "https://res.cloudinary.com/dyndxow8x/image/upload/v1682429440/wapaganda/default-thumb_tbe2gf.png";
  const title = content.title?.[lang] || "";
  const articleContent = content.content?.[lang] || "";

  const originalAuthor = content.additional?.author || "";
  const originalDate = new Date(content.additional?.date_published);
  const originalPublishDateFormatted = originalDate.toLocaleDateString(
    undefined,
    {
      year: "numeric",
      month: "long",
      day: "numeric",
    }
  );
  return (
    <>
      {loading === false ? (
        <div className="theoryArticle">
          <aside>
            <div
              className="img"
              style={{
                backgroundImage: `url(${defaultImage})`,
              }}
            ></div>
            <h2>{title}</h2>
            {originalAuthor && (
              <p className="description">
                {t("Original text was published by")}
                {" @" + originalAuthor} {t("in his Telegram channel on")}
                {originalPublishDateFormatted}:{" "}
                {content.attributes?.original_content_metadata.map(
                  (data, i) => (
                    <Button variant="text" key={i}>
                      <a
                        target="_blank"
                        rel="noreferrer noopener"
                        href={data.url}
                      >
                        {t("Original text")} {i + 1 + " "}
                        <OpenInNewIcon fontSize="10px" />
                      </a>
                    </Button>
                  )
                )}
              </p>
            )}
          </aside>
          <div className="content">
            <ReactMarkdown>{articleContent}</ReactMarkdown>
          </div>
        </div>
      ) : (
        <div className="loaderContainer">
          <div className="sk-circle">
            <div className="sk-circle1 sk-child"></div>
            <div className="sk-circle2 sk-child"></div>
            <div className="sk-circle3 sk-child"></div>
            <div className="sk-circle4 sk-child"></div>
            <div className="sk-circle5 sk-child"></div>
            <div className="sk-circle6 sk-child"></div>
            <div className="sk-circle7 sk-child"></div>
            <div className="sk-circle8 sk-child"></div>
            <div className="sk-circle9 sk-child"></div>
            <div className="sk-circle10 sk-child"></div>
            <div className="sk-circle11 sk-child"></div>
            <div className="sk-circle12 sk-child"></div>
          </div>
        </div>
      )}
    </>
  );
};

export default TheoryArticle;
