import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { personsUrl, requestPerson } from "../hooks/RestClient.jsx";
import log from "loglevel";
import { useToken } from "../hooks/useToken.jsx";

function Associates(props) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const t = useTranslation().t;
  const token = useToken();

  useEffect(() => {
    const fetchData = async () => {
      if (!token) {
        return;
      }
      try {
        setLoading(true);
        const res = await requestPerson(personsUrl(), token, props.id);

        if (res.error) {
          console.error(res.error); // Handle other errors or show a message
        }
        setData(res);
      } catch (error) {
        log.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [props.id, token]);

  return (
    <>
      {loading === true && <></>}
      {loading === false && (
        <>
          <h3>Associates:</h3>
          <div className="continer">
            <div className="img">
              <img
                src="https://res.cloudinary.com/dyndxow8x/image/upload/v1685462678/wapaganda/00000015_3_yewtvv.png"
                alt=""
              />
            </div>
            <div className="details">
              <p>{t("Name") + ": " + data.fullname_en}</p>
              <p>{t("Relationship") + ": " + props.relationship}</p>
              <p>{t("Born") + ": " + data.dob}</p>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Associates;
