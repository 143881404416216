import { useState } from "react";
import "../css/search.scss";

const list = [];

const SuggestionItem = ({ item, searchText }) => {
  return (
    <li>
      <span
        dangerouslySetInnerHTML={{
          __html: item.replace(
            new RegExp(searchText, "gi"),
            "<strong>$&</strong>"
          ),
        }}
      />
    </li>
  );
};

const SuggestInput = ({ items }) => {
  const [searchText, setSearchText] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const handleChange = (event) => {
    const value = event.target.value;
    setSearchText(value);

    if (value.length >= 3) {
      const suggestions = items.filter((item) =>
        item.toLowerCase().startsWith(value.toLowerCase())
      );
      setSuggestions(suggestions);
    } else {
      setSuggestions([]);
    }
  };

  return (
    <>
      <input
        type="text"
        className="search_input"
        value={searchText}
        onChange={handleChange}
      />
      {suggestions.length > 0 && (
        <ul className="dropdown_list">
          {suggestions.map((item) => (
            <SuggestionItem key={item} item={item} searchText={searchText} />
          ))}
        </ul>
      )}
    </>
  );
};

function Search() {
  return (
    <div className="search">
      <p className="prefix">Search</p>
      <SuggestInput items={list} />
    </div>
  );
}

export default Search;
