import { NavLink } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import "../css/diagramIntro.scss";

export default function DiagramIntro() {
  const t = useTranslation().t;
  const number_of_concepts = "number_of_concepts";
  return (
    <div className="DiagramIntro">
      <h2>{t("Ruskymir Creed (RUCR)")}</h2>
      <p>
        {t("Ruskymir Creed (RUCR) content", {
          number_of_concepts: number_of_concepts,
        })}
      </p>
      <div className="diagramPreview">
        <img src={t("svgUrl")} alt="RUCR" />
        <NavLink to="/Diagram">
          <Button>{t("View full size")}</Button>
        </NavLink>
      </div>
      <h4>{t("How it works")}</h4>
      <p>{t("How it works content")}</p>
      <h4>{t("WIP")}</h4>
      <p>{t("WIP content")}</p>
    </div>
  );
}
