import { useTranslation } from "react-i18next";

import "../css/ComingSoon.scss";

function ComingSoon() {
  const t = useTranslation().t;

  return (
    <div className="comingSoon">
      <div className="comingSoonText">
        <h1>{t("Coming soon!")}</h1>
        <h4>
          {t("We are sorry,")}
          <br />
          {t("This section is still under development")}
        </h4>
      </div>
      <img
        src="https://res.cloudinary.com/dyndxow8x/image/upload/v1702222140/DALL-E_7_vtekzl.png"
        alt=""
      />
    </div>
  );
}

export default ComingSoon;
