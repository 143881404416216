import React, { useEffect, useState, useMemo } from "react";
import { useMediaQuery } from "react-responsive";

import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import Badge from "@mui/material/Badge";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

/**
 *
 * @param {object} data
 * @returns {JSX.Element}
 */
function AirTimeTab({ data }) {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
  const t = useTranslation().t;
  const lang = localStorage.getItem("lang") || "en";
  const total = data.airtime.total || {};

  const appearances = useMemo(() => {
    return data.airtime.episodes || {};
  }, [data]);

  const convertSecondsToHoursMinutes = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds - hours * 3600) / 60);
    // PadStart(2, '0') ensures that hours and minutes are always at least two digits
    return `${hours.toString().padStart(2, "0")}h ${minutes
      .toString()
      .padStart(2, "0")}m`;
  };

  // to find the last date on air
  const lastOnAirDate = dayjs(total.most_recent_appearance_date);

  const [highlightedDays, setHighlightedDays] = React.useState([]);
  const [selectedDate, setSelectedDate] = useState(
    lastOnAirDate.format("YYYY-MM-DDT00:00:00[Z]")
  );

  // to check if the person has any episodes on Air
  const [wasOnAir, setWasOnAir] = useState(false);
  const [onAirToday, setOnAirToday] = useState(false);

  useEffect(() => {
    // fetch onAir dates
    const fetchOnAirDates = () => {
      const dates = appearances.map((appearance) => appearance.date);
      setHighlightedDays(dates);
    };

    // to check if the person has any episodes on Air
    const wasOnAirCheck = () => {
      let isOnAirToday = false;
      let isOnAir = false;
      if (appearances) {
        isOnAir = true;
        appearances.forEach((episode) => {
          episode.episodes.forEach((episode) => {
            let episodeDate = dayjs(episode.episode_date);
            let dateSelected = dayjs(selectedDate);

            if (episodeDate.isSame(dateSelected, "day")) {
              isOnAirToday = true;
            }
          });
        });
      }
      setWasOnAir(isOnAir);
      setOnAirToday(isOnAirToday);
    };
    fetchOnAirDates();
    wasOnAirCheck();
  }, [selectedDate, appearances]);

  function isDateHighlighted(date, highlightedDates) {
    return highlightedDates.some((highlightedDate) =>
      dayjs(highlightedDate)
        .startOf("day")
        .isSame(dayjs(date).startOf("day"), "day")
    );
  }
  function ServerDay(props) {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const isSelected =
      !outsideCurrentMonth && isDateHighlighted(day, highlightedDays);

    return (
      <Badge
        key={day.toString()}
        overlap="circular"
        badgeContent={isSelected ? <div className="square"></div> : undefined}
      >
        <PickersDay
          {...other}
          outsideCurrentMonth={outsideCurrentMonth}
          day={day}
        />
      </Badge>
    );
  }

  return (
    <>
      {(wasOnAir && (
        <div className="airtime">
          <div className="calendar">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar
                defaultValue={lastOnAirDate}
                slots={{
                  day: ServerDay,
                }}
                slotProps={{
                  day: {
                    highlightedDays,
                  },
                }}
                onChange={(newValue) => {
                  setSelectedDate(newValue.format("YYYY-MM-DDT00:00:00[Z]"));
                }}
              />
            </LocalizationProvider>
          </div>

          <div className="showscontiner">
            {(onAirToday && (
              <div className="shows">
                {appearances
                  .filter((appearance) =>
                    dayjs(selectedDate).isSame(dayjs(appearance.date), "day")
                  )
                  .map((appearance, index) => {
                    return (
                      <div key={index}>
                        <div className="show">
                          <div className="showSummary">
                            <h6>
                              {`${t("Appearances")}: 
                                ${appearance.appearances}`}
                            </h6>
                            <h6>
                              {`${t("Time on air")}: 
                                ${convertSecondsToHoursMinutes(
                                  appearance.airtime
                                )}`}
                            </h6>
                          </div>
                          {appearance.episodes.map((episode, episodeIndex) => {
                            return (
                              <div className="episode" key={episodeIndex}>
                                <p>
                                  {
                                    episode.media_role +
                                      " • " +
                                      episode.segment_name[lang]
                                    // ? this is for episode platform
                                    // +" • " +"episode.source"
                                  }
                                </p>
                                <h5>{episode.episode_title}</h5>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
              </div>
            )) || (
              <div className="submitData">
                <p>{t("No appearances known at this date")}</p>
                <p>{t("Are we missing something? Let us know")}</p>
                <Button>{t("Submit data")}</Button>
              </div>
            )}
          </div>
          {(isDesktopOrLaptop && (
            <div className="airtimeSummary">
              <h4>
                {`${t("Total known air time")}:
            ${convertSecondsToHoursMinutes(total.total_airtime)} / ${t(
                  "Total known appearances"
                )}: ${total.appearances_count} / ${t("Appears as")}:
            ${total.roles}`}
              </h4>
            </div>
          )) || (
            <div className="airtimeSummary">
              <h4>
                {`${t("Total known air time")}:
            ${convertSecondsToHoursMinutes(total.total_airtime)} `}
              </h4>
              <h4>{`${t("Total known appearances")}: ${
                total.appearances_count
              } `}</h4>
              <h4>{`${t("Appears as")}: ${total.roles}`}</h4>
            </div>
          )}
        </div>
      )) || (
        <div className="submitData">
          <p>{t("No appearances known")}</p>
          <p>{t("Are we missing something? Let us know")}</p>
          <Button>{t("Submit data")}</Button>
        </div>
      )}
    </>
  );
}

export default AirTimeTab;
