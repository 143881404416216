import { useState } from "react";
import { MapInteractionCSS } from "react-map-interaction";
import { ReactSVG } from "react-svg";
import { useTranslation } from "react-i18next";
import "../css/diagram.scss";

const Diagram = () => {
  const t = useTranslation().t;

  const [value, setValue] = useState({
    scale: 0.8,
    translation: { x: -1000, y: -800 },
  });
  const [translationBounds, setTranslationBounds] = useState({});
  const minScale = 0.3;
  const maxScale = 1.3;

  const newSceal = () => {
    if (value.scale > 0.6) {
      setTranslationBounds({});
    }
    if (value.scale <= 0.6) {
      setTranslationBounds({
        xMin: -900,
        xMax: 1200,
        yMin: -1150,
        yMax: 800,
      });
    }
    if (value.scale <= 0.3) {
      setTranslationBounds({
        xMin: -900,
        xMax: 1200,
        yMin: -950,
        yMax: 800,
      });
    }
  };

  return (
    <div id="Diagram">
      <MapInteractionCSS
        value={value}
        translationBounds={translationBounds}
        minScale={minScale}
        maxScale={maxScale}
        onChange={(newValue) => {
          setValue(newValue);
          newSceal();
        }}
      >
        <ReactSVG src={t("svgUrl")} />
      </MapInteractionCSS>
    </div>
  );
};

export default Diagram;
