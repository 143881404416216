import "../css/errorPage.scss";
import { useTheme } from "../hooks/useThemeContext";

export default function Error500() {
  return (
    <div className="errorPage">
      <div className="container">
        <div className="logo">
          {(useTheme() === "light-mode" && (
            <img
              className="logo"
              alt="logo"
              src="https://res.cloudinary.com/dyndxow8x/image/upload/v1688258460/wapaganda/Subjective_Agency_Logo_black_lkyi0i.svg"
            />
          )) || (
            <img
              className="logo"
              alt="logo"
              src="https://res.cloudinary.com/dyndxow8x/image/upload/v1684860381/wapaganda/Subjective_Agency_Logo_white_mu9afr.svg"
            />
          )}
        </div>
        <div className="text">
          <h2>Oops! Something Went Wrong.</h2>
          <p>
            We&apos;re really sorry, but it seems like our website is having a
            bit of a hiccup. Our team is already on the case, and we&apos;re
            working to fix it as soon as possible.
          </p>
          <br />
          <span className="errorCode">
            <h4>Error Code: </h4>
            <h4> 500 - Internal Server Error</h4>
          </span>
          <p>We appreciate your patience and understanding!</p>
        </div>
      </div>
    </div>
  );
}
