import { useState, createContext, useContext } from "react";

/**
 *! This function is currently disabled. The website is only available in dark mode at the moment.
 *
 * To enable the theme switching functionality, follow these steps:
 *
 * 1. Replace the `value` prop in `ThemeContext.Provider` from `"dark-mode"` with `{theme}`.
 * 2. Replace the `className` prop in the wrapper `<div>` from `"dark-mode"` with `{theme}`.
 * 3. Uncomment the theme switch button in the header.jsx.
 */

export const ThemeContext = createContext("dark-mode");
export const ThemeUpdateContext = createContext();

/**
 * A custom hook to eassy access the current theme value.
 * @returns {string} The current theme, either "dark-mode" or "light-mode".
 */
export const useTheme = () => {
  return useContext(ThemeContext);
};

/**
 * A custom hook to eassy access toggleTheme to update the theme.
 * @returns {() => void} The function to toggle between "dark-mode" and "light-mode".
 */
export const useThemeUpdate = () => {
  return useContext(ThemeUpdateContext);
};

/**
 * Provides the theme context and theme update context to its children.
 * @param {React.ReactNode} props.children - The children components to be wrapped by the ThemeProvider.
 * @returns {React.ReactElement} The ThemeProvider component.
 */
export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(() => {
    const pervTheme = localStorage.getItem("theme");
    return pervTheme != null ? pervTheme : "dark-mode";
  });

  const toggleTheme = () => {
    const newTheme = theme === "light-mode" ? "dark-mode" : "light-mode";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
    console.info(`Changing theme to ${newTheme}`);
  };

  return (
    <ThemeContext.Provider value={"dark-mode"}>
      <ThemeUpdateContext.Provider value={toggleTheme}>
        <div className={"dark-mode"}>
          <div className="BG">{children}</div>
        </div>
      </ThemeUpdateContext.Provider>
    </ThemeContext.Provider>
  );
};
