import "../css/errorPage.scss";
import { useTheme } from "../hooks/useThemeContext";
export default function Error404() {
  return (
    <div className="errorPage">
      <div className="container">
        <div className="logo">
          {(useTheme() === "light-mode" && (
            <img
              className="logo"
              alt="logo"
              src="https://res.cloudinary.com/dyndxow8x/image/upload/v1688258460/wapaganda/Subjective_Agency_Logo_black_lkyi0i.svg"
            />
          )) || (
            <img
              className="logo"
              alt="logo"
              src="https://res.cloudinary.com/dyndxow8x/image/upload/v1684860381/wapaganda/Subjective_Agency_Logo_white_mu9afr.svg"
            />
          )}
        </div>
        <div className="text">
          <h2>Oops! Something Went Wrong.</h2>
          <p>
            We're really sorry, but it seems like our website is having a bit of
            a hiccup. Our team is already on the case, and we're working to fix
            it as soon as possible.
          </p>
          <br />
          <h4>Here’s What You Can Do:</h4>
          <ul>
            <li>
              Double-Check: Make sure the URL in the address bar is correct.
              Sometimes it's as simple as a typo.
            </li>
            <li>
              Go Back and Try Again: You can{" "}
              <a href="/">return to our homepage</a> and start your journey
              over.
            </li>
          </ul>
          <br />
          <span className="errorCode">
            <h4>Error Code: </h4>
            <h4> 400 - Bad Request</h4>
          </span>
          <p>
            We apologize for the inconvenience and appreciate your
            understanding.
          </p>
        </div>
      </div>
    </div>
  );
}
