import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { orgUrl, requestOrgs, requestOrg } from "../hooks/RestClient.jsx";
import "../css/organizations.scss";
import Popup from "reactjs-popup";
import { FilterMenu } from "../components/FilterMenu.jsx";
import {
  Sort as SortIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
  Feedback as FeedbackIcon,
  PeopleAlt as PeopleAltIcon,
  OpenInNew as OpenInNewIcon,
} from "@mui/icons-material";
import {
  MenuItem,
  Divider,
  Pagination,
  Stack,
  Button,
  IconButton,
} from "@mui/material";
import { ReactSVG } from "react-svg";
import { useMediaQuery } from "react-responsive";
import Loading from "../components/Loading.jsx";
import { useTheme } from "../hooks/useThemeContext.jsx";
import { useToken } from "../hooks/useToken.jsx";

/**
 * extract domain name from a link, result: example.com
 * @param {string} link - web site link as a string
 * @returns {string}
 */
const extractWebsiteName = (link) => {
  let hostname = "";
  if (link) {
    try {
      const url = new URL(link);
      hostname = url.hostname;
      if (hostname.startsWith("www.")) {
        hostname = hostname.substring(4);
      }
    } catch (error) {
      console.error("Invalid URL:", link);
    }
  }
  return hostname;
};

/**
 * Popup component for org details
 * @param {boolean} props.popupState - The state of the popup, indicating whether it is open (true) or closed (false).
 * @param {Function} props.setPopupState - The function to set the state of the popup.
 * @param {Object} props.popupData - The data to be displayed in the popup passed from "Notes component" by the user clicking a slected note, if there wasnt any note slected (note link shared or page reload) data need to be fetched from the api.
 * @returns {JSX.Element}
 * @constructor
 */

const OrgPopup = ({ popupState, setPopupState, popupData, page }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const t = useTranslation().t;
  const lang = t("lang");
  const { id } = useParams();
  const [cardData, setCardData] = useState([]);
  const [cardLoading, setCardLoading] = useState(true);
  const token = useToken();

  useEffect(() => {
    //this to prevent page scrolling while the popup is open
    const elements = document.querySelectorAll("[autoFocus]");
    elements.forEach((el) => el.removeAttribute("autoFocus"));
    // fetching is not used unless no data is passed down to the popup, typically this happens when reloading the page or directly visiting `Organizations/id`
    const fetchData = async () => {
      if (!token) {
        return;
      }
      try {
        setCardLoading(true);
        const res = await requestOrg(orgUrl(), token, id);
        if (res.error) {
          console.error(res.error);
        }
        setCardData(res);
      } catch (error) {
        console.error(error);
      } finally {
        setCardLoading(false);
      }
    };
    // this check if the data of the org is pass down to the pop up if it's not it fetch the data from the api  let orgData = {};
    const cardInfoCheck = () => {
      if (popupData) {
        if (popupData.id == id && popupState == true) {
          setCardData(popupData);
          setCardLoading(false);
        } else if (popupData.id !== id && popupState == true) {
          fetchData();
        }
      } else if (!popupData && popupData.length == 0 && popupState == true) {
        fetchData();
      }
    };
    cardInfoCheck();

    const disableScrolling = () => {
      if (popupState == true) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "scroll";
      }
    };
    disableScrolling();
  }, [id, popupState, popupData, cardData.id]);

  const closeModal = () => {
    setPopupState(false);
    setCardData([]);
    navigate(`/Organizations?page=${page}`);
  };

  const orgName = () => {
    if (cardData?.name?.[lang]) {
      return cardData?.name?.[lang];
    } else if (!cardData?.name?.[lang]) {
      if (cardData?.name?.ru) {
        return cardData?.name?.ru;
      } else if (cardData?.name?.uk) {
        return cardData?.name?.uk;
      } else if (cardData?.name?.en) {
        return cardData?.name?.en;
      }
    }
  };

  const shortName = cardData?.short_name || {};
  const orgType = cardData?.org_type || {};
  const parentOrgs = cardData?.parent_orgs || [];
  const sourceUrl = cardData?.source_url;

  const ppl = cardData?.ppl || [];

  const [currentPplPage, setCurrentPplPage] = useState(1);
  const personPerPplPage = 4;

  const totalPages = Math.ceil(ppl.length / personPerPplPage);

  const startIndex = (currentPplPage - 1) * personPerPplPage;
  const endIndex = startIndex + personPerPplPage;

  const currentPpl = ppl.slice(startIndex, endIndex);

  const handlePplPageChange = (event, value) => {
    setCurrentPplPage(value);
  };
  return (
    <Popup
      open={popupState}
      closeOnDocumentClick
      onClose={closeModal}
      className="orgCard"
    >
      {(!cardLoading && (
        <div className={`popup ${theme}`}>
          <div className={`continier ${theme}`}>
            <div className="name">
              <h2>{orgName()}</h2>
              <p>
                <i>{shortName[lang]}</i>
              </p>
            </div>
            <div className="info">
              {orgType[lang] && (
                <>
                  <h6>{t("Org typet")} :</h6>
                  <p>{orgType[lang]}</p>
                </>
              )}
              {sourceUrl && (
                <>
                  <h6>{t("Source url")} :</h6>
                  <a href={sourceUrl}> {extractWebsiteName(sourceUrl)}</a>
                </>
              )}
            </div>
            <div className="parents">
              <h6>{t("Parent orgs")}:</h6>
              {parentOrgs &&
                parentOrgs.map((parent, i) => (
                  <Link key={i} to={`../Organizations/${parent.id}`}>
                    {parent.name.en}
                  </Link>
                ))}
            </div>
            {currentPpl.length > 0 && (
              <div className="people">
                <div className="head">
                  <h4>People</h4>
                  <Stack spacing={2}>
                    <Pagination
                      count={totalPages}
                      page={currentPplPage}
                      onChange={handlePplPageChange}
                      size="small"
                    />
                  </Stack>
                </div>
                {currentPpl &&
                  currentPpl.map((person, i) => {
                    return (
                      <Link
                        key={i}
                        className="worker"
                        to={`../person/${person.id}`}
                      >
                        <img
                          src="https://res.cloudinary.com/dyndxow8x/image/upload/v1680670389/shoomow_silhouetter_of_old_evil_sick_bold_man_7c55d4ba_4444_49f6_adobe_express_1_nmc0tc.png"
                          alt=""
                        />
                        <div className="personData">
                          <p>{person.fullname.en}</p>
                          <i>
                            <p>{person.role?.en}</p>
                          </i>
                        </div>
                      </Link>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      )) || (
        <div className={`popup ${theme}`}>
          <div className={`continier ${theme}`} autoFocus>
            <Loading />
          </div>
        </div>
      )}
    </Popup>
  );
};

/**
 * organizations page
 * @param {boolean} props.modalState - The state of the popup, indicating whether it is open (true) or closed (false).
 * @returns
 * @constructor
 */
export function Orgs({ modalState }) {
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const t = useTranslation().t;
  const lang = t("lang");
  // const isDesktopOrLaptop = useMediaQuery({ minWidth: 1224 });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const [orgsPageData, setOrgsPageData] = useState([]);
  const [orgs, setOrgs] = useState([]);

  const [popupState, setPopupState] = useState(modalState);
  const [modalData, setModalData] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // the values that will be used for the api request
  const [currentPage, setCurrentPage] = useState(searchParams.get("page") || 1);
  const pageSize = 18;
  const totalPages = Math.ceil(orgsPageData.total / pageSize);
  const [sortBy, setSortBy] = useState(`name.${lang}`);
  const [sortingDirection, setSortingDirection] = useState("asc");

  const token = useToken();

  useEffect(() => {
    const fetchData = async () => {
      if (!token) {
        return;
      }
      try {
        setLoading(true);
        const filters = {
          sortBy: sortBy == "name" ? `${sortBy}.${lang}` : sortBy,
          sortingDirection: sortingDirection,
          currentPage: currentPage,
          pageSize: pageSize,
        };
        const res = await requestOrgs(orgUrl(), token, filters);

        if (res.error) {
          console.error(res.error); // Handle other errors or show a message
        }
        setOrgsPageData(res);
        setOrgs(res.page);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    // noinspection JSIgnoredPromiseFromCall
    fetchData();
  }, [currentPage, lang, sortBy, sortingDirection, token]);

  // to control sortby button
  const [menuAnchor, setMenuAnchor] = useState({});
  const openMenu = (menuId, event) => {
    setMenuAnchor((prevMenuAnchor) => ({
      ...prevMenuAnchor,
      [menuId]: event.currentTarget,
    }));
  };
  const closeMenu = (menuId) => {
    setMenuAnchor((prevMenuAnchor) => ({
      ...prevMenuAnchor,
      [menuId]: null,
    }));
  };

  return (
    <div className="orgPage">
      <div className="orgHeader">
        <div className="orgHeader__bg">
          <div className="orgHeader__bgOverlay">
            <div className="orgHeader__container">
              <h2>{t("Organizations")}</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="orgs">
        <div className="bar">
          <Button
            id="customized-button"
            variant="contained"
            disableElevation
            onClick={(event) => openMenu("tagsSortByAnchor", event)}
            startIcon={<SortIcon />}
          ></Button>
          <FilterMenu
            id="customized-menu"
            MenuListProps={{
              "aria-labelledby": "customized-button",
            }}
            anchorEl={menuAnchor["tagsSortByAnchor"]}
            open={Boolean(menuAnchor["tagsSortByAnchor"])}
            onClick={() => closeMenu("tagsSortByAnchor")}
          >
            {tagsSortingOptions.map((option, i) => [
              <MenuItem
                key={i}
                onClick={() => {
                  setSortBy(option.StateToSet);
                  setSortingDirection(option.SortingDirection);
                }}
                disableRipple
              >
                {option.icon}
                {t(option.lable)}
              </MenuItem>,
              option.divider && (
                <Divider key={`${i}-divider`} sx={{ my: 0.5 }} />
              ),
            ])}
          </FilterMenu>
          <Stack spacing={2}>
            <Pagination
              count={totalPages || 1}
              page={currentPage}
              siblingCount={isTabletOrMobile ? 0 : 1}
              onChange={(e, value) => {
                setCurrentPage(value);
                navigate(`/Organizations?page=${value}`);
              }}
            />
          </Stack>
        </div>
        {(!loading && (
          <div className="continer">
            {orgs?.map((org, i) => {
              const shortName = org?.short_name || {};
              const orgName = () => {
                if (org?.name[lang]) {
                  return org?.name[lang];
                } else if (!org?.name[lang]) {
                  if (org?.name.ru) {
                    return org?.name.ru;
                  } else if (org?.name.uk) {
                    return org?.name.uk;
                  } else if (org?.name.en) {
                    return org?.name.en;
                  }
                }
              };

              return (
                <div className="orgRow" key={i}>
                  <div className={`people ${theme}`}>
                    <Popup
                      on={["hover", "focus"]}
                      trigger={() => (
                        <>
                          {<PeopleAltIcon />}
                          {org.ppl.length}
                        </>
                      )}
                      position="top center"
                      closeOnDocumentClick
                      className="peoplePopup"
                    >
                      <span>
                        number of known employees <br /> at this organisation
                      </span>
                    </Popup>
                  </div>{" "}
                  <Link
                    className="orgName"
                    onClick={() => {
                      setModalData(org);
                      setPopupState(true);
                    }}
                    to={`/Organizations/${org.id}`}
                  >
                    <div className="name">
                      {org.state_affiliated && (
                        <ReactSVG src="https://res.cloudinary.com/dyndxow8x/image/upload/v1715567934/ixpfrb_1_c1apad.svg" />
                      )}
                      <h3>{orgName()}</h3>
                    </div>
                    <p>
                      <i>{shortName[lang]}</i>
                    </p>
                  </Link>
                  <a
                    href={org.source_url}
                    target="_blank"
                    rel="noreferrer noopener"
                    className="website"
                  >
                    {(org.source_url && (
                      <>
                        {<OpenInNewIcon />}
                        <p> {extractWebsiteName(org.source_url)}</p>
                      </>
                    )) ||
                      ""}
                  </a>
                  <IconButton
                    className="reportButton"
                    size="small"
                    aria-label="report info button"
                  >
                    <FeedbackIcon />
                  </IconButton>
                </div>
              );
            })}
          </div>
        )) || (
          <div className="continer">
            <Loading />
          </div>
        )}
      </div>
      <OrgPopup
        popupState={popupState}
        setPopupState={setPopupState}
        popupData={modalData}
        page={currentPage}
        lang={lang}
      />
    </div>
  );
}
export default Orgs;

const tagsSortingOptions = [
  {
    lable: "A-Z",
    StateToSet: "name",
    SortingDirection: "asc",
    icon: <ArrowDownwardIcon />,
    divider: false,
  },
  {
    lable: "Z-A",
    StateToSet: "name",
    SortingDirection: "desc",
    icon: <ArrowUpwardIcon />,
    divider: false,
  },
];
