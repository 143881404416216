import { createContext, useContext, useState, useEffect } from "react";

let BACKEND_URL = import.meta.env.VITE_BACKEND_URL || "http://localhost:11000";
let AUTH_USERNAME = import.meta.env.VITE_AUTH_USERNAME;
let AUTH_PASSWORD = import.meta.env.VITE_AUTH_PASSWORD;

const TokenContext = createContext();

export const TokenProvider = ({ children }) => {
  const [token, setToken] = useState(null);

  const fetchAuthToken = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          username: AUTH_USERNAME,
          password: AUTH_PASSWORD,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      console.info(`AuthToken was fetched`);
      const data = await response.json();
      setToken(data.access_token);
    } catch (error) {
      console.error("Error fetching token:", error);
    }
  };

  useEffect(() => {
    fetchAuthToken();
  }, []);

  return (
    <TokenContext.Provider value={token}>{children}</TokenContext.Provider>
  );
};

export const useToken = () => {
  return useContext(TokenContext);
};
