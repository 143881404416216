import { Link } from "react-router-dom";
import "../css/person.scss";
import { t } from "i18next";

/** Person component
 * Renders a person's thumbnail, name, DOB and age
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

function processValues(values) {
  if (values === null) {
    return "";
  } else if (typeof values === "string") {
    return values;
  }
}

export const Person = (props) => {
  const lang = localStorage.getItem("lang") || "en"; // default to English if lang is not set
  const fullnames = {
    en: props.fullname_en || "",
    ru: props.fullname_ru || "",
    uk: props.fullname_uk || "",
  };
  const firstName = fullnames[lang].split(" ")[0];
  const lastName = fullnames[lang].split(" ")[1];
  const small_image = props.thumb;
  const dob = props.dob;
  const dod = props.dod;
  const known_fors = {
    en: props.known_for?.en ?? "",
    ru: props.known_for?.ru ?? "",
    uk: props.known_for?.uk ?? "",
  };
  const aliases = {
    en: props.aliases?.en ?? "",
    ru: props.aliases?.ru ?? "",
    uk: props.aliases?.uk ?? "",
  };
  const knownFor = processValues(known_fors[lang]);
  const knownAs = processValues(aliases[lang]);
  let age = null;
  if (dod) {
    // Calculate age when person died
    age = `${new Date(dod).getFullYear() - new Date(dob).getFullYear()} ${""}`;
  } else if (dob == null) {
    // Calculate age when person died
    age = `${""}`;
  } else {
    // Calculate current age if date of death is null
    age = ` ${new Date().getFullYear() - new Date(dob).getFullYear()}${""} `;
  }
  const id = props.id;
  const baseUrl = window.location.origin;
  const link = `${baseUrl}/person/${id}`;

  return (
    <Link to={link} className="person2">
      <div className="container">
        <div className="thumbnail-container">
          <img
            className={dod ? "thumbnail fadedout" : "thumbnail"}
            alt="thumbnail"
            src={small_image}
          />
          <div className="person-info">
            <h4>{firstName}</h4>
            <h4>
              {lastName}
              {age && `, ${age}`}
              {dod && "*"}
            </h4>
          </div>
        </div>
        <div className="side">
          <div className="dis">
            <p className="knownas">
              {knownAs && (
                <p>
                  <b> Also known as: </b> {knownAs}
                </p>
              )}
            </p>
            {(knownFor && (
              <p className="knownfor">
                <b>most known for: </b>
                {knownFor}
              </p>
            )) || <p>{t("Oops... Seems like we're missing this one.")}</p>}
            {/*
            <div className="icon1">
              <img src="https://hatscripts.github.io/circle-flags/flags/ru.svg" />

              <img
                src="https://res.cloudinary.com/dyndxow8x/image/upload/v1702868113/wapaganda/icons/wiki_qrlzly.svg"
                alt=""
              />
              <img
                src="https://res.cloudinary.com/dyndxow8x/image/upload/v1702868111/wapaganda/icons/y_exctlb.svg"
                alt=""
              />
              <img
                src="https://res.cloudinary.com/dyndxow8x/image/upload/v1702868111/wapaganda/icons/ig_dbp2z7.svg"
                alt=""
              />
            </div>
            <div className="icon2">
              <img
                src="https://res.cloudinary.com/dyndxow8x/image/upload/v1703014317/wapaganda/icons/5_qi8wd5.png"
                alt=""
              />
              <img
                src="https://res.cloudinary.com/dyndxow8x/image/upload/v1702990262/wapaganda/icons/image_2023-12-15_18-59-17_rfgrjy.png"
                alt=""
              />
              <img
                src="https://res.cloudinary.com/dyndxow8x/image/upload/v1702990261/wapaganda/icons/image_2023-12-15_19-01-09_bxhgjo.png"
                alt="Religious figure"
              />
              <img
                src="https://res.cloudinary.com/dyndxow8x/image/upload/v1702990260/wapaganda/icons/image_2023-12-15_19-04-46_mvls6l.png"
                alt="Religious figure"
              />
            </div>
            */}
            {dod && <p className="diedAt">{`*died at ${age} years old`}</p>}
          </div>
        </div>
      </div>
    </Link>
  );
};
